<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">TEATRO REAL</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t('Copla.Common.Subtitles.Configuration') }}</h2>
          <div class="form-group row container">
            <label
              for="title"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Name") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <input
                type="text"
                class="form-control"
                name="title"
                id="title"
                min="1"
                v-model="title"
              />
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Language')
            }}</label>
            <div class="col-10 mt-2">
              <select v-model="language" class="custom-select">
                <option
                  v-bind:value="id"
                  v-for="(value, id) in filteredLanguages"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Voice1')
            }}</label>
            <div class="col-4 mt-2">
              <select v-model="voice1" class="custom-select">
                <option
                  :value="id"
                  v-for="(value, id) in voicesSelect"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Speed')
            }}</label>
            <div class="col-4 mt-2">
              <select v-model="speed1" class="custom-select">
                <option
                  :value="value.id"
                  v-for="(value, id) in speedsList"
                  :key="id"
                >
                  {{ value.value }}
                </option>
              </select>
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Voice2')
            }}</label>
            <div class="col-4 mt-2">
              <select v-model="voice2" class="custom-select">
                <option
                  :value="id"
                  v-for="(value, id) in voicesSelect"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Speed')
            }}</label>
            <div class="col-4 mt-2">
              <select v-model="speed2" class="custom-select">
                <option
                  :value="value.id"
                  v-for="(value, id) in speedsList"
                  :key="id"
                >
                  {{ value.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4">
          <h2>{{ $t('Copla.Views.Synthesis.ScriptLoad') }}</h2>
          <div class="form-group row">
            <label for="file" class="col-2 col-form-label text-center">
              {{ $t('Copla.Views.Synthesis.AudioScript') }} (.srt)
            </label>
            <div class="col-7">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".srt"
                required
              />
            </div>
            <div class="text-center">
              <button
                type="button"
                id="upload"
                name="upload"
                class="btn btn-primary"
                v-on:click="uploadFile"
                v-if="file != ''"
              >
                {{ $t('Copla.Common.Buttons.Load') }}
              </button>
            </div>
          </div>
        </div>
        <table
          class="table table-sm table-striped table-dark mt-4"
          v-if="script.length > 0"
        >
          <thead>
            <tr class="d-flex">
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Date') }}
              </th>
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Name') }}
              </th>
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Language') }}
              </th>
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Male') }}
              </th>
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Female') }}
              </th>
              <th class="col">
                {{ $t('Copla.Views.TeatroReal.Status') }}
              </th>
              <th>
                <span class="reload" v-on:click="getTRData()">&#x21bb;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, i) in showScripts" :key="i" :id="i" class="d-flex">
              <td class="col" style="vertical-align: top">
                {{ datetimeString(data.date) }}
              </td>
              <td class="col" style="vertical-align: top">
                {{ data.name }}
              </td>
              <td class="col" style="vertical-align: top">
                {{ data.language }}
              </td>
              <td class="col" style="vertical-align: top">
                {{ data.voice_m }}
              </td>
              <td class="col" style="vertical-align: top">
                {{ data.voice_f }}
              </td>
              <td
                class="col"
                style="vertical-align: top"
                v-html="getUrlDownload(data.id, data.status)"
              ></td>
            </tr>
          </tbody>
        </table>
        <div class="">
          <Pagination
            v-if="script"
            :total-pages="totalPages"
            :per-page="pagination"
            :current-page="currentPage"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
//import axios from "axios";
import MainMenu from '../components/MainMenu'
import AzureSynthesizer from '../js/AzureSynthesizer'
import AzureAPISynthesizer from '../js/AzureAPISynthesizer'
import formatSubtitlesAPI from '../js/formatSubtitlesAPI'
import JSZip from 'jszip'
import ModalLoading from '../components/ModalLoading.vue'
import Pagination from "../components/Pagination.vue"
export default {
  name: 'TeatroReal',
  components: {
    MainMenu,
    ModalLoading,
    Pagination
  },
  data () {
    return {
      text: '',
      languages: this.$t('Copla.Common.Lists.Languages'),
      filteredLanguages: {},
      voices: [],
      voicesSelect: {},
      language: 'es-ES',
      voice1: '',
      voice2: '',
      speed1: '',
      speed2: '',
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: '',
      script: [],
      showModalLoading: false,
      messageModal: '',
      error: '',
      title: 'Título',
      loading: 0,
      percentage: 0,
      pagination: 10,
      currentPage: 1,
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      placeholder: this.$t('Copla.Views.Synthesis.Placeholder'),
      speedsList: [],
    }
  },
  mounted () {
    let self = this
    this.speedsList = this.speeds()
    let azureAPISynthesizer = new AzureAPISynthesizer({
      key: process.env.VUE_APP_AZURE_KEY,
      location: process.env.VUE_APP_AZURE_LOCATION
    })
    azureAPISynthesizer.getVoicesList().then(response => {
      self.voices = response.data.locale
      let fixedVoice1 = 11
      let fixedVoice2 = 9
      self.voice1 = self.voices[self.language][fixedVoice1].ShortName
      self.voice2 = self.voices[self.language][fixedVoice2].ShortName
      self.filteredLanguages = Object.keys(self.languages)
        .filter(key => Object.prototype.hasOwnProperty.call(self.voices, key))
        .reduce((acc, key) => {
          acc[key] = self.languages[key]
          return acc
        }, {})
      const entries = Object.entries(self.filteredLanguages)
      entries.sort(([, value1], [, value2]) => value1.localeCompare(value2))
      self.filteredLanguages = entries.reduce((acc, curr) => {
        acc[curr[0]] = curr[1]
        return acc
      }, {})
    })
    self.getTRData()
  },
  watch: {
    voices: function (val) {
      if (val.length == 0) {
        this.voicesSelect = {}
      } else {
        let self = this
        let list = self.voices[self.language]
        let newList = {}
        list.forEach(function (value, index) {
          let name = value.Name + ' (' + value.Gender + ')'
          let key = value.ShortName
          newList[key] = name
          if (index == 0 && self.language != 'es-ES') {
            self.voice1 = key
            self.voice2 = key
          }
        })
        this.voicesSelect = newList
      }
    },
    language: function (val) {
      let self = this
      let list = self.voices[val]
      let newList = {}
      list.forEach(function (value, index) {
        let name = value.Name + ' (' + value.Gender + ')'
        let key = value.ShortName
        newList[key] = name
        if (index == 0) {
          self.voice1 = key
          self.voice2 = key
        }
      })
      this.voicesSelect = newList
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.script.length / this.pagination);
    },
    showScripts() {
      return this.script.slice(
        (this.currentPage - 1) * this.pagination,
        this.pagination * this.currentPage
      );
    }
  },
  methods: {
    speeds() {
      let values = []
      for (let i=-100;i<0;i++) {
        let id = i+'%'
        let value = i+'%'
        values.push({id: id, value: value})
      }
      values.push({id:'',value:'Normal'})
      for (let i=1;i<=200;i++) {
        let id = '+'+i+'%'
        let value = '+'+i+'%'
        values.push({id: id, value: value})
      }
      return values
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    getUrlDownload (id, status) {
      if (status == 'Descargar') {
        return "<a style='color: white;text-decoration: underline;' href='https://formatsubtitles.copla.io/files/teatroreal/"+id+"/audios/ad.zip'>Descargar</a>"
      } else {
        return status
      }
    },
    showModal (status, message = '') {
      this.messageModal = message
      this.showModalLoading = status
    },
    getTRData: function () {
      let self = this
      this.fsAPI.getTeatroReal().then(response => {
        self.script = response.data.data
      })
    },
    uploadFile: function () {
      let self = this
      let formData = new FormData()
      formData.append('_file', this.file)
      formData.append('_name', this.title)
      formData.append('_language', this.language)
      formData.append('_voiceM', this.voice1)
      formData.append('_voiceF', this.voice2)
      formData.append('_speedM', this.speed1)
      formData.append('_speedF', this.speed2)
      this.fsAPI.postTeatroReal(formData).then(response => {
        if (!response.error) {
          self.file = ''
          self.getTRData()
        } else {
          console.log('error')
        }
      })
    },
    datetimeString: function (ts) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')
      let date = new Date(ts * 1000)
      let hours = zeroPad(date.getHours(), 2)
      let minutes = zeroPad(date.getMinutes(), 2)
      let seconds = zeroPad(date.getSeconds(), 2)
      let day = zeroPad(date.getDate(), 2)
      let month = zeroPad(date.getMonth() + 1, 2)
      let year = date.getFullYear()
      return (
        day +
        '/' +
        month +
        '/' +
        year +
        ' - ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      )
    },
    postStartit: function (url) {
      let self = this
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      let params = {
        _url: url,
        _language: self.language
      }
      this.fsAPI.postStartit(params).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          this.getAudiodescriptions(response.data.data.caf)
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    getAudiodescriptions: function (id) {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.fsAPI.getAudiodescriptions(id).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          response.data.data.forEach(function (ads) {
            self.script.push(ads.text)
          })
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    downloadZip: function () {
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      this.percentage = 0
      this.showModal(
        true,
        this.$t('Copla.Common.Modal.Creating') + ' ' + this.percentage + '%'
      )
      var zip = new JSZip()
      let self = this
      let count = 0
      this.script.forEach(function (value, i) {
        let file = i + 1
        if (file < 10) {
          file = '00' + file
        } else if (file < 100) {
          file = '0' + file
        }
        let fileName = file + '.wav'
        let params = { mode: 1, text: value }
        params.language = self.language
        params.voice = self.voice1
        let azureSynth = new AzureSynthesizer(params)
        azureSynth.createFile().then(response => {
          var blob = new Blob([response], {
            type: 'audio/wav'
          })
          zip.file(fileName, blob)
          count++
          self.percentage = Math.round((count * 100) / self.script.length)
          self.showModal(
            true,
            self.$t('Copla.Common.Modal.Creating') + ' ' + self.percentage + '%'
          )
          if (count >= self.script.length) {
            self.showModal(true, self.$t('Copla.Common.Modal.Downloading'))
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              let url = URL.createObjectURL(content)
              let element = document.createElement('a')
              element.setAttribute('href', url)
              element.setAttribute('download', 'AD.zip')
              element.style.display = 'none'
              element.click()
              self.showModal(false)
            })
          }
        })
      })
    },
    playScript: function (i) {
      let file = i + 1
      if (file < 10) {
        file = '00' + file
      } else if (file < 100) {
        file = '0' + file
      }
      let fileName = file + '.wav'
      this.play(this.script[i], fileName)
    },
    downloadScript: function (i) {
      let file = i + 1
      if (file < 10) {
        file = '00' + file
      } else if (file < 100) {
        file = '0' + file
      }
      let fileName = file + '.wav'
      this.download(this.script[i], fileName)
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    downloadFile: function (data, fileName) {
      var blob = new Blob([data], {
        type: 'audio/wav; codecs=MS_PCM'
      })
      let url = URL.createObjectURL(blob)
      let element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', fileName)
      element.style.display = 'none'
      element.click()
    },
    play: function (text, fileName) {
      if (fileName == '') {
        fileName = 'audio.wav'
      }
      if (text != '') {
        let params = { mode: 0, text: text }
        this.connect(params, fileName)
      }
    },
    download: function (text, fileName) {
      if (fileName == '') {
        fileName = 'audio.wav'
      }
      if (text != '') {
        let params = { mode: 1, text: text }
        this.connect(params, fileName)
      }
    },
    connect: function (params, fileName) {
      let self = this
      params.language = this.language
      params.voice = this.voice1
      let azureSynth = new AzureSynthesizer(params)
      azureSynth.createFile().then(response => {
        if (params.mode == 1) {
          self.downloadFile(response, fileName)
        }
      })
    }
  }
}
</script>

<style scoped>
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}

.loading {
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#error {
  font-size: 1.2em;
  color: red;
  font-weight: bold;
}

.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
</style>
